/**
 * Componente SelectCustom.
 * Renderiza un campo de selección con validación y estilos personalizados.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {Function} props.register - Función de registro para el manejo de formularios.
 * @param {Object} props.errors - Objeto que contiene los errores de validación.
 * @param {boolean} props.isValid - Indica si el campo es válido.
 * @param {string} props.keyInput - Clave única para identificar el campo de selección.
 * @param {string} props.label - Etiqueta para el campo de selección.
 * @param {string} props.placeholder - Texto de marcador de posición para el campo de selección.
 * @param {Array} props.data - Datos para las opciones del campo de selección.
 *
 * @returns {JSX.Element} Elemento JSX que representa el campo de selección personalizado.
 */
function SelectCustom({
  register,
  errors,
  isValid,
  keyInput,
  label,
  placeholder,
  data,
}) {
  return (
    <div className="mb-2 w-full">
      <label className="text-sm" htmlFor={keyInput}>
        {label}
      </label>
      <div className="relative w-full">
        <select
          {...register(keyInput)}
          id={keyInput}
          name={keyInput}
          className={`select__input--rounded
                        ${
                          errors[keyInput]
                            ? "select__input--error"
                            : isValid
                            ? "select__input--valid "
                            : "select__input--neutral"
                        }`}
        >
          <option className="text-gray-400" value="">
            {placeholder}
          </option>
          {data.map((e, index) => (
            <option key={index} value={e}>
              {e}
            </option>
          ))}
        </select>
        <div className="select__icon">
          <svg
            className="w-6 h-6 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M5.25 7.75l4.25 4.25 4.25-4.25" />
          </svg>
        </div>
      </div>
      {errors[keyInput] && (
        <small className="text--error">*{errors[keyInput].message}</small>
      )}
    </div>
  );
}

export default SelectCustom;
