import React, { useState } from "react";
import upload from "../../assets/upload.svg";
import InputCustom from "../../shared/components/InputCustom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import apiConfig from "../../config/apiConfig";
import HttpClient from "../../api/httpClient";
import PreviewDocument from "../../shared/components/PreviewDocument";
import Footer from "../Footer";

// Inicializa el cliente API con la URL base
const apiClient = new HttpClient(apiConfig.baseURL);

// Define el esquema de validación usando Yup
const schema = yup.object({
  name: yup
    .string()
    .required("La razón social es requerida.")
    .matches(/^[A-Za-zÀ-ÿ0-9\s.,&'-]{2,50}$/,"El formato ingresado no es válido."),
  fullName: yup
    .string()
    .required("El nombre es requerido.")
    .matches(/^[A-zÀ-ú ]{2,50}$/, "El formato ingresado no es válido."),
  secondName: yup
    .string()
    .required("El apellido paterno es requerido.")
    .matches(/^[A-zÀ-ú ]{2,50}$/, "El formato ingresado no es válido."),
  thirdName: yup
    .string()
    .required("El apellido materno es requerido.")
    .matches(/^[A-zÀ-ú ]{2,50}$/, "El formato ingresado no es válido."),
  legalRepresentativeName: yup
    .string()
    .required("El Nombre del representante legal es requerido.")
    .matches(/^[A-zÀ-ú ]{2,50}$/, "No se admiten caracteres especiales."),
  legalRepresentativeEmail: yup
    .string()
    .required("El Correo Electrónico del representante legal es requerido.")
    .email("El Correo Electrónico no es válido."),
  phone: yup
    .string()
    .required("El teléfono es requerido.")
    .matches(/^[0-9+ ]{7,12}$/, "Solo se admiten números."),
  email: yup
    .string()
    .required("El correo Electrónico es requerido.")
    .email("El correo electrónico no es válido."),
  taxRefResponsible: yup
    .string()
    .required("El RFC es requerido.")
    .matches(/^([A-ZÑ&]{3,4}[0-9]{6}[A-Z\d]{2}[A\d]?)$/, "El RFC no es válido."),
  address: yup
    .string()
    .required("La dirección es requerida.")
    .matches(/^[A-zÀ-ú0-9&\/\-., ]{2,50}$/, "El formato ingresado no es válido."),
  address2: yup
    .string()
    .required("La dirección es requerida.")
    .matches(/^[A-zÀ-ú0-9&\/\-., ]{2,50}$/, "El formato ingresado no es válido."),
});

// Componente principal User
function User({ onSubmit, toastNotifier }) {
  // Configuración del formulario usando react-hook-form y Yup para validación
  const {
    register,
    handleSubmit,
    setValue,
    //formState: { errors, isValid, isSubmitted, touchedFields },
    formState: { errors, isSubmitted, touchedFields },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: {
      typeBuss: "001",
      typeCont: "001",
    },
  });

  // Estado para la acción a realizar (alta o baja)
  const [action, setAction] = useState("up");

  // Estado para los archivos cargados
  const [files, setFiles] = useState({
    cardIdB64UserFrontal: { value: null, name: "", valid: "", message: "" },
    cardIdB64UserReverse: { value: null, name: "", valid: "", message: "" },
    cardIdB64AuthorizingFrontal: {
      value: null,
      name: "",
      valid: "",
      message: "",
    },
    cardIdB64AuthorizingReverse: {
      value: null,
      name: "",
      valid: "",
      message: "",
    },
  });

  // Estado para el indicador de carga
  const [loading, setLoading] = useState(false);

  // Función para convertir un archivo a base64
  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      if (file && file instanceof Blob) {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result.split(",")[1]);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      } else {
        reject(
          new Error(
            "El archivo proporcionado no es válido o no es del tipo Blob"
          )
        );
      }
    });
  };

  // Función para convertir un archivo a base64
  const handleFileChange = async (e, field) => {
    const file = e.target.files[0];

    if (file && file.size > 50 * 1024 * 1024) {
      setFiles({
        ...files,
        [field]: {
          value: null,
          valid: "error",
          message: "El archivo excede el tamaño máximo permitido de 50 MB.",
        },
      });
    }
    try {
      const base64File = await convertFileToBase64(file);
      setFiles({
        ...files,
        [field]: { value: base64File, name: file.name, valid: "success" },
      });
    } catch (error) {
      console.error("Error al convertir el archivo:", error);
    }
  };

  // Maneja el envío del formulario
  const onSubmitForm = async (formData) => {
    if (
      files.cardIdB64UserFrontal.value &&
      files.cardIdB64UserReverse.value &&
      (action === "down" ||
        (files.cardIdB64AuthorizingFrontal.value &&
          files.cardIdB64AuthorizingReverse.value))
    ) {
      setLoading(true);

      try {
        const jsonObject = {
          id: 0,
          actionType: action === "up" ? 1 : 2,
          creditGrantingCompany: formData.name,
          nameResponsible: formData.fullName,
          secondName: formData.secondName,
          thirdName: formData.thirdName,
          taxRefResponsible: formData.taxRefResponsible,
          addressResponsible: formData.address,
          addressResponsible2: formData.address2,
          phone: formData.phone,
          email: formData.email,
          businessType: "001",
          typeOfContract: "003",
          legalRepresentativeName: formData.legalRepresentativeName,
          legalRepresentativeEmail: formData.legalRepresentativeEmail,
          cardIdB64UserFrontal: files.cardIdB64UserFrontal.value,
          cardIdB64UserReverse: files.cardIdB64UserReverse.value,
          cardIdB64AuthorizingFrontal:
          action === "down"
            ? "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAgUBA5c0SdAAAAAASUVORK5CYII="
            : files.cardIdB64AuthorizingFrontal.value,
        cardIdB64AuthorizingReverse:
          action === "down"
            ? "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAgUBA5c0SdAAAAAASUVORK5CYII="
            : files.cardIdB64AuthorizingReverse.value,
          //cardIdB64AuthorizingFrontal: action === "up" ? files.cardIdB64AuthorizingFrontal.value : null,
          //cardIdB64AuthorizingReverse: action === "up" ? files.cardIdB64AuthorizingReverse.value : null,
        };
        apiClient.setAuthToken(sessionStorage.getItem("token"));
        await apiClient.post(apiConfig.services.createUser, jsonObject);
        onSubmit("Formulario enviado con éxito.", {
          type: "success",
          message: "Formulario enviado con éxito.",
        });
      } catch (error) {
        let message = "Existió un problema al enviar el formulario.";

        if (error.response) {
          const status = error.response.status;
          if (status === 401) {
            message = "Tu solicitud ha expirado.";
          } else {
            const statusMessage =
              error.response.data?.statusResponse?.message ||
              error.response.data?.message ||
              message;
            message = `(Error ${status}) ${statusMessage}`;
          }
        } else if (error.request) {
          message = "No hubo respuesta del servidor. Intenta nuevamente.";
        }

        toastNotifier({ type: "error", message });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="container--form">
        <form
          onSubmit={handleSubmit(onSubmitForm)}
          autoComplete="off"
          className="form--shadow"
        >
          <div className="header--title">
            <label className="header__text">
              (Anexo E) Alta y Baja de usuario
            </label>
          </div>

          <div className="mt-2">
            <label className="label--primary" htmlFor="action">
              Acción a realizar:
            </label>
            <div className="flex-box--gap">
              <div>
                <input
                  type="radio"
                  name="actiontype"
                  defaultChecked
                  value="up"
                  onChange={(e) => setAction(e.target.value)}
                />
                <span className="text-sm pl-2">Alta</span>
              </div>
              <div>
                <input
                  type="radio"
                  name="actiontype"
                  value="down"
                  onChange={(e) => setAction(e.target.value)}
                />
                <span className="text-sm pl-2">Baja</span>
              </div>
            </div>
          </div>

          <div className="content-separator"></div>

          <div className="panel--dashed">
            <label className="label--primary">Carga de documentos:</label>
            <div className="flex-container--column">
              <label className="text-sm" htmlFor="user">
                1. Identificación oficial del {action === "up" ? "usuario de consulta" : "funcionario"}: {" "}
                <PreviewDocument isIne={true} />
              </label>
              <div className="grid-container--gapped">
                <label
                  className={`mt-2 flex-container--column justify-center items-center gap-x-2 w-full bg-lightGray border 
                     rounded-lg p-1 cursor-pointer
                     ${
                       isSubmitted && !files.cardIdB64UserFrontal.value
                         ? "border-red"
                         : "border-lightGray"
                     }
                     `}
                  htmlFor="fileUF"
                >
                  <span className="text--label--primary">Elegir archivo</span>
                  <img width={20} src={upload} alt="" />
                  <span className="text--label--primary--bold">Frente</span>
                  <input
                    onChange={(e) =>
                      handleFileChange(e, "cardIdB64UserFrontal")
                    }
                    type="file"
                    id="fileUF"
                    className="hidden"
                    accept=".pdf,image/jpeg,image/png"
                  />
                  <small className="italic text--label--primary">
                    {files.cardIdB64UserFrontal.name}
                  </small>
                </label>
                {isSubmitted && !files.cardIdB64UserFrontal.value ? (
                  <small className="text--error">
                    *El archivo es requerido.
                  </small>
                ) : null}

                <label
                  className={`mt-2 flex-container--column justify-center items-center gap-x-2 w-full bg-lightGray border 
                    rounded-lg p-1 cursor-pointer 
                    ${
                      isSubmitted && !files.cardIdB64UserReverse.value
                        ? "border-red"
                        : "border-lightGray"
                    }`}
                  htmlFor="fileUR"
                >
                  <span className="text--label--primary">Elegir archivo</span>
                  <img width={20} src={upload} alt="" />
                  <span className="text--label--primary--bold">Reverso</span>
                  <input
                    onChange={(e) =>
                      handleFileChange(e, "cardIdB64UserReverse")
                    }
                    type="file"
                    id="fileUR"
                    className="hidden"
                    accept=".pdf,image/jpeg,image/png"
                  />
                  <small className="italic text--label--primary">
                    {files.cardIdB64UserReverse.name}
                  </small>
                </label>
                {isSubmitted && !files.cardIdB64UserReverse.value ? (
                  <small className="text--error">
                    *El archivo es requerido.
                  </small>
                ) : null}
              </div>

              {action === "up" && (
                <>
                  <div className="flex-container--column mt-4">
                    <label className="text-sm">
                      2. Identificación oficial del funcionario: {" "}
                      <PreviewDocument isIne={true} />
                    </label>
                    <div className="grid-container--gapped">
                      <label
                        className={`mt-2 flex-container--column justify-center items-center gap-x-2 w-full bg-lightGray 
                      border rounded-lg p-1 cursor-pointer 
                      ${
                        isSubmitted && !files.cardIdB64AuthorizingFrontal.value
                          ? "border-red"
                          : "border-lightGray"
                      }`}
                        htmlFor="fileAF"
                      >
                        <span className="text--label--primary">Elegir archivo</span>
                        <img width={20} src={upload} alt="" />
                        <span className="text--label--primary--bold">Frente</span>
                        <input
                          onChange={(e) =>
                            handleFileChange(
                              e,
                              "cardIdB64AuthorizingFrontal"
                            )
                          }
                          type="file"
                          id="fileAF"
                          className="hidden"
                          accept=".pdf,image/jpeg,image/png"
                        />
                        <small className="italic text--label--primary">
                          {files.cardIdB64AuthorizingFrontal.name}
                        </small>
                      </label>
                      {isSubmitted && !files.cardIdB64AuthorizingFrontal.value ? (
                        <small className="text--error">
                          *El archivo es requerido.
                        </small>
                      ) : null}

                      <label
                        className={`mt-2 flex-container--column justify-center items-center gap-x-2 w-full bg-lightGray border 
                      ${
                        isSubmitted && !files.cardIdB64AuthorizingReverse.value
                          ? "border-red"
                          : "border-lightGray"
                      } 
                      rounded-lg p-1 cursor-pointer`}
                        htmlFor="fileAR"
                      >
                        <span className="text--label--primary">Elegir archivo</span>
                        <img width={20} src={upload} alt="" />
                        <span className="text--label--primary--bold">Reverso</span>
                        <input
                          onChange={(e) =>
                            handleFileChange(
                              e,
                              "cardIdB64AuthorizingReverse"
                            )
                          }
                          type="file"
                          id="fileAR"
                          className="hidden"
                          accept=".pdf,image/jpeg,image/png"
                        />
                        <small className="italic text--label--primary">
                          {files.cardIdB64AuthorizingReverse.name}
                        </small>
                      </label>
                      {isSubmitted && !files.cardIdB64AuthorizingReverse.value ? (
                        <small className="text--error">
                          *El archivo es requerido.
                        </small>
                      ) : null}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="panel--dashed">
            <label className="label--primary">
              Datos del responsable de la clave: {" "}
            </label>
            <div className="grid-container--responsive">
              <InputCustom
                maxLength={255}
                errors={errors}
                isValid={touchedFields.name}
                register={register}
                keyInput={"name"}
                label={"Razón social:"}
                placeholder={"Razón social"}
              />

              <InputCustom
                errors={errors}
                isValid={touchedFields.email}
                register={register}
                keyInput={"email"}
                label={"Correo electrónico:"}
                placeholder={"Correo Electrónico"}
              />

              <InputCustom
                register={register}
                errors={errors}
                isValid={touchedFields.legalRepresentativeName}
                keyInput={"legalRepresentativeName"}
                label={"*Nombre(s) del representante legal:"}
                placeholder={"Nombre(s) del representante legal"}
              />

              <InputCustom
                register={register}
                errors={errors}
                isValid={touchedFields.legalRepresentativeEmail}
                keyInput={"legalRepresentativeEmail"}
                label={"*Correo Electrónico del representante legal:"}
                placeholder={"Correo Electrónico del representante legal"}
              />

              <InputCustom
                maxLength={10}
                regexChange={/^\d*$/}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    setValue("phone", value, { shouldValidate: true });
                  }
                }}
                errors={errors}
                isValid={touchedFields.phone}
                register={register}
                keyInput={"phone"}
                label={"Teléfono:"}
                placeholder={"Teléfono"}
              />

              <InputCustom
                onChange={(e) => {
                  const value = e.target.value.toUpperCase();
                  setValue("taxRefResponsible", value, { shouldValidate: true });
                }}
                errors={errors}
                isValid={touchedFields.taxRefResponsible}
                register={register}
                keyInput={"taxRefResponsible"}
                label={"RFC del responsable:"}
                placeholder={"RFC"}
                maxLength={13}
              />

              <InputCustom
                errors={errors}
                isValid={touchedFields.fullName}
                register={register}
                keyInput={"fullName"}
                label={"Nombre(s):"}
                placeholder={"Nombre(s)"}
              />

              <InputCustom
                errors={errors}
                isValid={touchedFields.secondName}
                register={register}
                keyInput={"secondName"}
                label={"Apellido Paterno:"}
                placeholder={"Paterno"}
              />

              <InputCustom
                errors={errors}
                isValid={touchedFields.secondName}
                register={register}
                keyInput={"thirdName"}
                label={"Apellido Materno:"}
                placeholder={"Materno"}
              />

              <InputCustom
                top={"top-[70px]  min-[422px]:top-[45px]"}
                errors={errors}
                isValid={touchedFields.address}
                register={register}
                keyInput={"address"}
                label={"Dirección completa del responsable:"}
                placeholder={"Dirección 1"}
              />

              <InputCustom
                errors={errors}
                isValid={touchedFields.address2}
                register={register}
                keyInput={"address2"}
                label={""}
                placeholder={"Dirección 2"}
              />
            </div>
          </div>

          <div className="action-container--end">
            <button className="button--primary">
              <img
                src="/img/icons/ico-iniciar-10.png"
                alt="Ícono"
                className="button__icon"
              />
              Guardar
            </button>
          </div>
        </form>
        <Footer />
      </div>
    </>
  );
}

export default User;