import CheckIconSvg from "../../assets/check.svg";

/**
 * Componente de icono de verificación.
 * Muestra un icono de check utilizando una imagen SVG.
 * @returns {JSX.Element} Elemento de imagen con el icono de check.
 */
const CheckIcon = () => <img width={30} alt="checkSvg" src={CheckIconSvg} />;

/**
 * Componente de icono de error.
 * Muestra un icono de error utilizando un emoji de X.
 * @returns {JSX.Element} Elemento de span con el icono de error.
 */
const ErrorIcon = () => <span style={{ color: "red" }}>❌</span>;

/**
 * Componente de entrada personalizado.
 * Renderiza un campo de entrada de texto con validación y estilos personalizados.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {number} [props.maxLength=50] - Longitud máxima permitida para el campo de entrada.
 * @param {RegExp} [props.regexChange] - Expresión regular para validar la entrada.
 * @param {Function} props.register - Función de registro para el manejo de formularios.
 * @param {Object} props.errors - Objeto que contiene los errores de validación.
 * @param {boolean} props.isValid - Indica si el campo es válido.
 * @param {string} props.keyInput - Clave única para identificar el campo de entrada.
 * @param {string} props.label - Etiqueta para el campo de entrada.
 * @param {string} props.placeholder - Texto de marcador de posición para el campo de entrada.
 * @param {string} [props.top] - Posición superior personalizada para el icono de validación.
 * @param {Function} [props.onChange] - Función de manejo de cambio de entrada.
 *
 * @returns {JSX.Element} Elemento de entrada personalizado.
 */
function InputCustom({
  maxLength,
  regexChange,
  register,
  errors,
  isValid,
  keyInput,
  label,
  placeholder,
  top,
  onChange,
}) {
  /**
   * Maneja el evento antes de la entrada.
   * Previene la entrada si no coincide con la expresión regular proporcionada.
   *
   * @param {InputEvent} e - Evento de entrada.
   */
  const handleBeforeInput = (e) => {
    if (regexChange && !regexChange.test(e.data)) {
      e.preventDefault();
    }
  };
  /**
   * Renderiza el componente de entrada personalizado.
   *
   * @returns {JSX.Element} Elemento JSX que representa el campo de entrada con validación.
   */
  return (
    <div
      className="w-full"
      style={{ position: "relative", marginBottom: "1rem" }}
    >
      <label className="text-sm" htmlFor={keyInput}>
        {label}
      </label>
      <input
        id={keyInput}
        name={keyInput}
        type="text"
        {...register(keyInput)}
        style={{ width: "100%", paddingRight: "24px" }}
        className={`${
          errors[keyInput]
            ? "form__input--error"
            : isValid && !errors[keyInput]
            ? "form__input--success"
            : "form__input"
        } `}
        placeholder={placeholder}
        onChange={onChange ? onChange : register(keyInput).onChange}
        onBeforeInput={handleBeforeInput}
        maxLength={maxLength ? maxLength : 50}
      />
      <span
        className={`${label ? (top ? top : "top-[45px]") : "top-[23px]"} `}
        style={{
          position: "absolute",
          right: "8px",
          transform: "translateY(-50%)",
          pointerEvents: "none",
        }}
      >
        {errors[keyInput] ? (
          <ErrorIcon />
        ) : (
          isValid && !errors[keyInput] && <CheckIcon />
        )}
      </span>
      {errors[keyInput] && (
        <small className="text--error">*{errors[keyInput].message}</small>
      )}
    </div>
  );
}

export default InputCustom;
