import { useState } from "react";
import IdentificationImage from "../../components/content/images/IdentificationImage";
import PowersImage from "../../components/content/images/PowersImage";

/**
 * Componente PreviewDocument.
 * Muestra un botón que al hacer clic abre un modal con una imagen de identificación o poderes,
 * dependiendo de la propiedad isIne.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {boolean} props.isIne - Indica si se debe mostrar la imagen de identificación (true) o de poderes (false).
 *
 * @returns {JSX.Element} Elemento JSX que representa el componente de vista previa del documento.
 */
function PreviewDocument({ isIne }) {
  // Estado que controla si el modal está abierto o cerrado.
  const [isModalOpen, setIsModalOpen] = useState(false);

  /**
   * Abre el modal estableciendo el estado isModalOpen a true.
   */
  const openModal = () => {
    setIsModalOpen(true);
  };

  /**
   * Cierra el modal estableciendo el estado isModalOpen a false.
   */
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {/* Botón que abre el modal al hacer clic */}
      <button
        type="button"
        onClick={openModal}
        className="bg-skyBlue rounded-full h-6 w-6 text-white cursor-pointer"
      >
        ?
      </button>
      {/* Modal que se muestra si isModalOpen es true */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content bg-white w-[600px] mx-4 md:mx-32">
            {/* Muestra la imagen de identificación o poderes según el valor de isIne */}
            {isIne ? <IdentificationImage /> : <PowersImage />}
            {/* Botón para cerrar el modal */}
            <button
              type="button"
              onClick={closeModal}
              className="bg-skyBlue text-white font-medium border rounded-lg p-2 text-[16px]"
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default PreviewDocument;
