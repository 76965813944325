
/**
 * Configuración de la API.
 * Define la URL base y los endpoints de los servicios disponibles.
 */
const apiConfig = {
  /**
   * URL base para las solicitudes de la API.
   * Se obtiene del entorno de ejecución.
   */
  baseURL: process.env.REACT_APP_BASE_URL,

  /**
   * Endpoints de los servicios de la API.
   */
  services: {
    /**
     * Endpoint para crear un representante legal.
     */
    createLegalRepresentative: '/api/v1/legalrepresentative',

    /**
     * Endpoint para crear funcionarios facultados.
     */
    createPublicWorkers: '/api/v1/publicworkers',

    /**
     * Endpoint para crear un usuario de consulta.
     */
    createUser: '/api/v1/users',
  },
};

export default apiConfig;
