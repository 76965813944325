// Importación de librerías y componentes necesarios
import React, { useState } from 'react';
import upload from '../../assets/upload.svg';
import SelectCustom from '../../shared/components/SelectCustom';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import apiConfig from '../../config/apiConfig';
import HttpClient from '../../api/httpClient';
import PreviewDocument from '../../shared/components/PreviewDocument';
import Footer from '../Footer';

// Inicialización del cliente HTTP con la URL base de la API
const apiClient = new HttpClient(apiConfig.baseURL);

// Definición del esquema de validación para el formulario
const schema = yup.object({
    idNationality: yup.string().required('Selecciona una opción válida.')
    .oneOf(['Mexicana', 'Extranjero'], 'Selecciona una opción válida.'),
});

// Componente principal para el formulario de Representante Legal
function LegalRepresentative({ onSubmit, toastNotifier }) {
    // Configuración del formulario utilizando react-hook-form y yup para la validación
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isValid, isSubmitted, touchedFields },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'all'
    });

    // Estado para manejar la acción a realizar (agregar o actualizar)
    const [action, setAction] = useState('add');
    // Estado para manejar los archivos cargados
    const [files, setFiles] = useState({
        cardIdB64UserFrontal: { value: null, name: '', valid: '', message: '' },
        cardIdB64UserReverse: { value: null, name: '', valid: '', message: '' },
        cardIdB64AuthorizingFrontal: { value: null, name: '', valid: '', message: '' },
        cardIdB64AuthorizingReverse: { value: null, name: '', valid: '', message: '' },
    });

    // Estado para manejar la carga de datos y notificaciones
    const [loading, setLoading] = useState(false);
    const [toastParams, setToastParams] = useState({ type: '', message: '' });
    const selectedOption = watch("idNationality");

    // Función para convertir un archivo a base64
    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            if (file && file instanceof Blob) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result.split(',')[1]);
                };
                reader.onerror = (error) => {
                    reject(error);
                };
                reader.readAsDataURL(file);
            } else {
                reject(new Error("El archivo proporcionado no es válido o no es del tipo Blob"));
            }
        });
    };

    // Manejo del cambio de archivo y validación de tamaño
    const handleFileChange = async (e, field) => {
        const file = e.target.files[0];
        if (file && file.size > 50 * 1024 * 1024) {
            setFiles({
                ...files,
                [field]: { value: null, valid: 'error', message: 'El archivo excede el tamaño máximo permitido de 50 MB.' },
            });
        }
        try {
            const base64File = await convertFileToBase64(file);
            setFiles({
                ...files,
                [field]: { value: base64File, name: file.name, valid: 'success' },
            });
        } catch (error) {
            console.error("Error al convertir el archivo:", error);
        }
    };

    // Función para manejar el envío del formulario
    const onSubmitForm = async (e) => {
        if (e.idNationality !== 'Extranjero') {
            if (files.cardIdB64AuthorizingFrontal.value &&
                files.cardIdB64AuthorizingReverse.value &&
                files.cardIdB64UserFrontal.value &&
                files.cardIdB64UserReverse.value) {
                await sendData(e);
            }
        } else {
            if (files.cardIdB64AuthorizingFrontal.value &&
                files.cardIdB64AuthorizingReverse.value &&
                files.cardIdB64UserFrontal.value) {
                await sendData(e);
            }
        }
    };

    // Función para enviar los datos al servidor
    const sendData = async (e) => {
        setLoading(true);
        try {
            const jsonObject = {
                id: 0,
                actionType: action === 'update' ? 2 : 1,
                idNationality: e.idNationality === 'Mexicana' ? "1" : "2",
                nationality: e.idNationality,
                cardIdB64UserFrontal: files.cardIdB64UserFrontal.value,
                cardIdB64UserReverse: files.cardIdB64UserReverse.value,
                cardIdB64AuthorizingFrontal: files.cardIdB64AuthorizingFrontal.value,
                cardIdB64AuthorizingReverse: files.cardIdB64AuthorizingReverse.value,
            };
            apiClient.setAuthToken(sessionStorage.getItem('token'));
            await apiClient.post(apiConfig.services.createLegalRepresentative, jsonObject);
            onSubmit("Formulario enviado con éxito.", { type: 'success', message: "Formulario enviado con éxito." });
        } catch (error) {
            let message = "Existió un problema al enviar el formulario.";
            if (error.response) {
                const status = error.response.status;
                if (status === 401) {
                    message = "Tu solicitud ha expirado.";
                } else {
                    const statusMessage = error.response.data?.statusResponse?.message || error.response.data?.message || message;
                    message = `(Error ${status}) ${statusMessage}`;
                }
            } else if (error.request) {
                message = "No hubo respuesta del servidor. Intenta nuevamente.";
            }
            toastNotifier({ type: 'error', message });
        } finally {
            setLoading(false);
        }
    };

    // Renderizado del componente
    return (
        <>
            {loading && (
                <div className="overlay">
                    <div className="spinner"></div>
                </div>
            )}
            <div className='container--form'>
                <form onSubmit={handleSubmit(onSubmitForm)} autoComplete="off" className='form--shadow'>
                    <div className="header--title">
                        <label className="header__text">Cambio Representante Legal.</label>
                    </div>

                    <div className='mt-2'>
                        <label className='label--primary' htmlFor="action">Acción a realizar:</label>
                        <div className='flex-box--gap'>
                            <div>
                                <input name="actiontype" type="radio" value="update" onChange={(e) => setAction(e.target.value)} />
                                <span className="text-sm pl-2">Cambio</span>
                            </div>
                            <div>
                                <input name="actiontype" type="radio" defaultChecked value="add" onChange={(e) => setAction(e.target.value)} />
                                <span className="text-sm pl-2">Adición</span>
                            </div>
                        </div>
                    </div>

                    <div className="content-separator"></div>

                    <div className='panel--dashed'>
                        <label className='label--primary'>Carga de documentos:</label>

                        <SelectCustom errors={errors} isValid={isValid} register={register} data={['Mexicana', 'Extranjero']} keyInput={'idNationality'} label={'1. Selecciona la nacionalidad del representante legal:'} placeholder={'Selecciona un país'} />

                        <div className="flex-container--column">
                            <label className="text-sm" htmlFor="user">2. {
                                selectedOption === 'Mexicana' ? 'Identificación oficial del representante legal (INE)' : selectedOption === 'Extranjero' ? 'Identificación oficial del representante legal (Pasaporte)' : 'Identificación oficial del representante legal'
                            }
                            {/* Mostrar la píldora condicionalmente sin cambiar su funcionalidad */}
                            {(selectedOption === 'Mexicana' || selectedOption === 'Extranjero') && (
                                <PreviewDocument isIne={true} />
                            )}
                            </label>
                            <div className="grid-container--gapped">
                                <label className={`mt-2 flex-container--column justify-center items-center gap-x-2 w-full bg-lightGray 
                                border ${isSubmitted && !files.cardIdB64UserFrontal.value ? 'border-red' : 'border-lightGray'}
                                rounded-lg p-1 cursor-pointer`}
                                    htmlFor="fileUF">
                                    <span className="text--label--primary">Elegir archivo</span>
                                    <img width={20} src={upload} alt="" />
                                    <span className="text--label--primary--bold">Frente</span>
                                    <input onChange={(e) => handleFileChange(e, 'cardIdB64UserFrontal')} type="file" id="fileUF" className="hidden" accept=".pdf,image/jpeg,image/png" />
                                    <small className="italic text--label--primary">{files.cardIdB64UserFrontal.name}</small>
                                </label>
                                {isSubmitted && !files.cardIdB64UserFrontal.value ? <small className="text--error">*El archivo es requerido.</small> : null}

                                <label className={`${selectedOption === 'Extranjero' ? 'cursor-not-allowed' : 'cursor-pointer'} mt-2 flex-container--column justify-center items-center gap-x-2 w-full bg-lightGray border ${isSubmitted && !files.cardIdB64UserReverse.value ? 'border-red' : 'border-lightGray'}
                                rounded-lg p-1`}
                                    htmlFor="fileUR">
                                    <span className="text--label--primary">Elegir archivo</span>
                                    <img width={20} src={upload} alt="" />
                                    <span className="text--label--primary--bold">Reverso</span>
                                    <input disabled={selectedOption === 'Extranjero'} onChange={(e) => handleFileChange(e, 'cardIdB64UserReverse')} type="file" id="fileUR" className="hidden" accept=".pdf,image/jpeg,image/png" />
                                    <small className="italic text--label--primary">{files.cardIdB64UserReverse.name}</small>
                                </label>
                                {isSubmitted && !files.cardIdB64UserReverse.value && selectedOption !== 'Extranjero' ? <small className="text--error">*El archivo es requerido.</small> : null}
                            </div>
                        </div>

                        <div className="flex-container--column mt-4">
                            <label className="text-sm" htmlFor="user">3. Acta protocolizada de poderes:<PreviewDocument isIne={false} /></label>
                            <div className="grid-container--gapped">
                                <label className={`mt-2 flex-container--column justify-center items-center gap-x-2 w-full bg-lightGray border ${isSubmitted && !files.cardIdB64AuthorizingFrontal.value ? 'border-red' : 'border-lightGray'} rounded-lg p-1 cursor-pointer`} htmlFor="fileAF">
                                    <span className="text--label--primary">Elegir archivo</span>
                                    <img width={20} src={upload} alt="" />
                                    <span className="text--label--primary--bold">Acta I</span>
                                    <input onChange={(e) => handleFileChange(e, 'cardIdB64AuthorizingFrontal')} type="file" id="fileAF" className="hidden" accept=".pdf,image/jpeg,image/png" />
                                    <small className="italic text--label--primary">{files.cardIdB64AuthorizingFrontal.name}</small>
                                </label>
                                {isSubmitted && !files.cardIdB64AuthorizingFrontal.value ? <small className="text--error">*El archivo es requerido.</small> : null}

                                <label className={`mt-2 flex-container--column justify-center items-center gap-x-2 w-full bg-lightGray border ${isSubmitted && !files.cardIdB64AuthorizingReverse.value ? 'border-red' : 'border-lightGray'} rounded-lg p-1 cursor-pointer`} htmlFor="fileAR">
                                    <span className="text--label--primary">Elegir archivo</span>
                                    <img width={20} src={upload} alt="" />
                                    <span className="text--label--primary--bold">Acta II</span>
                                    <input onChange={(e) => handleFileChange(e, 'cardIdB64AuthorizingReverse')} type="file" id="fileAR" className="hidden" accept=".pdf,image/jpeg,image/png" />
                                    <small className="italic text--label--primary">{files.cardIdB64AuthorizingReverse.name}</small>
                                </label>
                                {isSubmitted && !files.cardIdB64AuthorizingReverse.value ? <small className="text--error">*El archivo es requerido.</small> : null}
                            </div>
                        </div>
                    </div>

                    <div className="action-container--end">
                        <button className="button--primary">
                            <img src="/img/icons/ico-iniciar-10.png" alt="Ícono" className="button__icon" />
                            Guardar
                        </button>
                    </div>
                </form>
                <Footer />
            </div>
        </>
    );
}

// Exportación del componente
export default LegalRepresentative;
