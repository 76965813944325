
import axios from 'axios';

/**
 * Clase HttpClient para manejar solicitudes HTTP usando Axios.
 */
class HttpClient {
  /**
   * Constructor de la clase HttpClient.
   * @param {string} baseURL - La URL base para las solicitudes HTTP.
   */
  constructor(baseURL = '') {
    this.client = axios.create({
      baseURL: baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  /**
   * Establece el token de autenticación en los encabezados de las solicitudes.
   * @param {string} token - El token de autenticación.
   */
  setAuthToken(token) {
    if (token) {
      this.client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete this.client.defaults.headers.common['Authorization'];
    }
  }

  /**
   * Realiza una solicitud GET.
   * @param {string} url - La URL del recurso.
   * @param {Object} params - Los parámetros de la solicitud.
   * @param {Object} config - Configuración adicional para la solicitud.
   * @returns {Promise<Object>} - La respuesta de la solicitud.
   */
  async get(url, params = {}, config = {}) {
    try {
      const response = await this.client.get(url, { params, ...config });
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  /**
   * Realiza una solicitud POST.
   * @param {string} url - La URL del recurso.
   * @param {Object} data - Los datos a enviar en la solicitud.
   * @param {Object} config - Configuración adicional para la solicitud.
   * @returns {Promise<Object>} - La respuesta de la solicitud.
   */
  async post(url, data = {}, config = {}) {
    try {
      const response = await this.client.post(url, data, config);
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  /**
   * Realiza una solicitud PUT.
   * @param {string} url - La URL del recurso.
   * @param {Object} data - Los datos a enviar en la solicitud.
   * @param {Object} config - Configuración adicional para la solicitud.
   * @returns {Promise<Object>} - La respuesta de la solicitud.
   */
  async put(url, data = {}, config = {}) {
    try {
      const response = await this.client.put(url, data, config);
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  /**
   * Realiza una solicitud DELETE.
   * @param {string} url - La URL del recurso.
   * @param {Object} config - Configuración adicional para la solicitud.
   * @returns {Promise<Object>} - La respuesta de la solicitud.
   */
  async delete(url, config = {}) {
    try {
      const response = await this.client.delete(url, config);
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  /**
   * Maneja los errores de las solicitudes HTTP.
   * @param {Object} error - El error lanzado por Axios.
   * @throws {Object} - El error procesado.
   */
  handleError(error) {
    if (error.response) {
      console.error('Error Response:', error.response);
      throw error;
    } else if (error.request) {
      console.error('Error Request:', error.request);
      throw error;
    } else {
      console.error('Error Message:', error.message);
      throw error;
    }
  }
}

export default HttpClient;
