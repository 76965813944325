import React, { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Componente ToastNotifier.
 * Muestra notificaciones de éxito o error utilizando react-toastify.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {string} props.type - Tipo de notificación ('success' o 'error').
 * @param {string} props.message - Mensaje a mostrar en la notificación.
 *
 * @returns {JSX.Element} Elemento JSX que representa el contenedor de notificaciones.
 */
const ToastNotifier = ({ type, message }) => {
    useEffect(() => {
        if (type && message) {
            if (type === 'success') {
                toast.success(message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            } else if (type === 'error') {
                toast.error(message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }, [type, message]);

    return <ToastContainer />;
};

export default ToastNotifier;
